// ---> BREAKPOINTS:
// -----> *** If you change these make sure to change them inside the functional components that use them for animations ***
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;

// ---> COLORS:
$color-background-main: #e0e0e0;
$color-box-shadow: #a7a7a7;

$color-secondary: #cacaca;
$color-alternate: beige;
$color-text-light: #fffbe8;
$color-text-dark: #010306;

$color-border: #444444;
