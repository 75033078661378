@import 'sass';

.Outer {
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	.Carousel {
		position: relative;
		overflow: hidden;
		width: 450px;
		height: 450px;
		border: 1px solid $color-border;
		border-radius: 10px;
		transition: all 0.5s ease;
		-ms-overflow-style: none; // IE and Edge
		scrollbar-width: none; // Firefox
		&::-webkit-scrollbar {
			display: none; // Chrome, Safari, Opera
		}

		@media screen and (max-height: 900px) {
			height: 400px;
		}
		@media screen and (max-height: 770px) {
			height: 380px;
		}
		@media screen and (max-height: 720px) {
			height: 350px;
			width: 350px;
		}
		@media screen and (max-width: 560px) {
			width: 350px;
		}
		@media screen and (max-height: 660px) {
			height: 300px;
			width: 300px;
		}
		@media screen and (max-height: 510px) {
			height: 200px;
			width: 300px;
			padding: 0;
		}
		@media screen and (max-width: 450px) {
			width: 300px;
		}
		@media screen and (max-width: 400px) {
			width: 250px;
		}
		@media screen and (max-width: 350px) {
			width: 220px;
		}

		.Inner {
			white-space: nowrap;
			transition: all 1s ease;
			display: flex;
			.Child {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-shrink: 0;
				width: 100%;
				height: 100%;

				img {
					border-radius: 10px 10px 0 0;
					width: 100%;
					height: 300px;
					@media screen and (max-width: 560px) {
						height: 233px;
					}
					@media screen and (max-height: 720px) {
						height: 233px;
					}
					@media screen and (max-height: 660px) {
						height: 200px;
					}
					@media screen and (max-width: 450px) {
						height: 200px;
					}
					@media screen and (max-width: 400px) {
						height: 167px;
					}
					@media screen and (max-width: 350px) {
						height: 147px;
					}
					@media screen and (max-height: 510px) {
						width: 300px;
						height: 200px;
					}
				}
				h4 {
					width: 100%;
					padding: 10px;
					font-family: 'Aboreto';
					font-size: 18px;
					margin: 10px 0 0;
					white-space: normal;
					@media screen and (max-height: 510px) {
						position: absolute;
						top: 0;
						color: white;
					}
				}
				p {
					width: 100%;
					white-space: normal;
					font-size: 16px;
					padding: 10px;
					font-weight: 300;
					line-height: 20px;
					height: 90px;
					overflow-y: scroll;
					-ms-overflow-style: none; // IE and Edge
					scrollbar-width: none; // Firefox
					&::-webkit-scrollbar {
						display: none; // Chrome, Safari, Opera
					}

					@media screen and (max-height: 510px) {
						display: none;
					}
				}
			}
		}
	}
	.Buttons {
		margin-top: 15px;
		@include flex-center;
		align-items: center;
		.Circle {
			margin: 0 10px;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			border: 1px solid $color-border;
			cursor: pointer;
			transition: all 0.5s linear;

			&Active {
				@extend .Circle;
				background-color: rgba($color: $color-border, $alpha: 0.5);
			}
		}
		.Arrows {
			color: $color-border;
			font-size: 15px;
			cursor: pointer;
			transition: 0.2s;
			&:active {
				color: white;
			}
		}
	}
}
