@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin disappear {
	@keyframes disappear {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}

@mixin appear {
	@keyframes appear {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
