* {
	margin: 0;
	padding: 0;
	font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', sans-serif;
	box-sizing: border-box;
}

body {
	overflow-x: hidden;
}

body, html {
	height: 100%;
	width: 100%;
	margin: 0;
}

a {
	text-decoration: none;
	color: black;
}

